<template>
    <div class="pay_bag">
        <img src="../../../../assets/vipLVTITLE.png" style="width: 200px">
        <div style="text-align: left" v-show="payItems && payItems.length > 0">
            <div class="pay_title"><span v-if="enterpriseinfo.enterpriseSecurityDepositFee" class="c-4">增加</span><span v-else>缴纳</span>保证金</div>
            <div class="button_container">
                <template v-for="(item, index) in payItems">
                    <template v-if="item.payfeeItem === 0">
                        <div class="button_container_item" :class="(bzj_choose === item.id) ? 'button_container_item_hover':''" @click="chooseBZJ(item)">
                            <img v-if="bzj_choose === item.id" src="../../../../assets/xuanzhongjiaobiao.png" class="button_container_item_choose">
                            <div class="button_container_item_desc">{{item.payfeeDescriber}}</div>
                            <div style="font-size: 14px;color: #9ca2ab">¥ <span style="color: #da1f1f;font-size: 22px;">{{item.payfeeAmount}}</span></div>
                        </div>
                    </template>
                </template>
            </div>
            <div class="pay_title"><span v-if="enterpriseinfo.isVipEnterprise" class="c-4">续费</span>VIP 年服务费</div>
            <div class="button_container">
                <template v-for="(item, index) in payItems">
                    <template v-if="item.payfeeItem === 1">
                        <div class="button_container_item" :class="(vip_choose === item.id) ? 'button_container_item_hover':''" @click="chooseVIP(item)">
                            <img v-if="vip_choose === item.id" src="../../../../assets/xuanzhongjiaobiao.png" class="button_container_item_choose">
                            <div class="button_container_item_desc">{{item.payfeeDescriber}}</div>
                            <div style="font-size: 14px;color: #9ca2ab">¥<span style="color: #da1f1f;font-size: 22px;">{{item.payfeeAmount}}</span></div>
                        </div>
                    </template>
                </template>
            </div>
            <div>
                <el-button type="primary" @click="onSubmit" :disabled="!bzj_choose && !vip_choose">立即充值</el-button>
            </div>
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
            <div>
                <div class="df-jc-s-b">
                    <div>支付金额</div>
                    <div>￥{{bzj_fee+vip_fee}}</div>
                </div>
                <div class="p-20 coupons">
                    <div v-for="(v,i) of couponList" class="coupon" :key="i">
                        <coupon2 :selectId="coupon.id" @clickCoupon="clickCoupon" :v="v"></coupon2>
                    </div>
                </div>
                <div class="df-jc-s-b">
                    <div>优惠券</div>
                    <div v-if="coupon.memberCardUserTicketFee">-{{coupon.memberCardUserTicketFee}}</div>
                    <div v-else>请选择优惠券</div>
                </div>
                <div class="df-jc-s-b m-t-10">
                    <div>需支付费用</div>
                    <div v-if="coupon.memberCardUserTicketFee">￥{{bzj_fee+vip_fee-coupon.memberCardUserTicketFee}}</div>
                    <div v-else>￥{{bzj_fee+vip_fee}}</div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="gotoPay(),dialogVisible = false">去支付</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import oucy from "../../../../util/oucyUtil";
import { userAuth, enterpriseinfo, financial } from '@/service/index.js';
import { localGet, localSet } from "@/store/store";
import Home from "@/views/Home";
export default {
    name: "pay",
    components: { Home },
    data() {
        return {
            bzj_choose: null,
            vip_choose: null,
            payItems: [],
            payMode: 0,
            qrCode: null,
            enterprise: {},
            enterpriseinfo: {},
            qrCode: null,
            enterpriseSecurityDepositFee: null,
            hasQrcodeShow: false,
            title: '',
            dialogVisible: 0,
            item: {},
            bzj_fee: 0,
            vip_fee: 0,
            couponList: [],
            coupon: {},
            ticketId: null,
            dialogVisible: false,
        }
    },
    computed: {
        payfeeAmount: function() {
            let amount = 0
            for (let v of this.payItems) {
                if (this.vip_choose == v.id || this.bzj_choose == v.id) {
                    amount += v.payfeeAmount
                }
            }
            return amount
        }
    },
    mounted() {
        this.enterprise = localGet('enterprise')
        console.log(this.enterprise)
        this.queryAllPayItems();

        // this.qrCode = new QRCode(this.$refs.myLoginQrCodeUrl, {
        //     // text: text,
        //     // text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfPreLogin+'?p='+me.$oucy.getUUID(),
        //     width: 146,
        //     height: 146,
        //     colorDark: '#000000',
        //     colorLight: '#ffffff',
        //     correctLevel: QRCode.CorrectLevel.H
        // })
        this.eventBus.$off(this.$oucy.msgKeys.SHOP_LEVEL_PAY_SUCCESS).$on(this.$oucy.msgKeys.SHOP_LEVEL_PAY_SUCCESS, (res) => {
            console.log('支付事件：');
            console.log(res);
            this.$oucy.replace('/paySucceed')

        })
        // 根据企业编码查询企业信息
        this.findEnterpriseByLtUserAuthId()

    },
    methods: {

        queryAllPayItems: function() {
            let me = this;
            oucy.postRequest('/client/enterprise/enterpriseshoplevelpayfee/getAllEnterpriseShopLevelPayfee', {}).then(res => {
                console.log(res);
                me.payItems = res;
            })
        },
        selectPaymode: function(payMode) {
            if (payMode === this.payMode) {
                this.payMode = null;
            } else {
                this.payMode = payMode;
            }
            // this.makeQrcode();
        },
        chooseBZJ: function(v) {
            if (this.bzj_choose && this.bzj_choose === v.id) {
                this.bzj_choose = null;
                this.bzj_fee = 0
            } else {
                this.bzj_choose = v.id;
                this.bzj_fee = v.payfeeAmount
            }
            // this.makeQrcode();
        },
        chooseVIP: function(v) {
            if (this.vip_choose && this.vip_choose === v.id) {
                this.vip_choose = null;
                this.vip_fee = 0
            } else {
                this.vip_fee = v.payfeeAmount
                this.vip_choose = v.id;
            }
            // this.makeQrcode();
        },
        makeQrcode() {
            let me = this;
            // if(!this.bzj_choose && this.vip_choose){
            //     this.$message('必须先交保证金才能升级为vip企业')
            //     return
            // }
            localSet('payObject', {
                payPlatform: 'browser',
                uuid: oucy.getUUID(),
                enterpriseId: this.enterprise.id,
                earnestMoney: me.bzj_choose,
                vipMoney: me.vip_choose,
                payMode: me.payMode,
                fee: this.vip_fee + this.bzj_fee - this.coupon.memberCardUserTicketFee
            })
            oucy.replace('/payCenter/pay?type=enterpriseUpgrade')
        },
/*        queryEffectTicket() {
            financial.queryEffectTicket({}).then(res => {
                console.log(res)
            })
        },*/
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        },
        gotoPay() {
            let me = this;
            let payObject={
                payPlatform: 'browser',
                uuid: oucy.getUUID(),
                enterpriseId: this.enterprise.id,
                earnestMoney: me.bzj_choose,
                vipMoney: me.vip_choose,
                payMode: me.payMode,
                ticketId: this.coupon.id,
                fee: this.vip_fee + this.bzj_fee - (this.coupon && this.coupon.memberCardUserTicketFee?this.coupon.memberCardUserTicketFee:0),
                type: 'enterpriseUpgrade',
            }
            localSet('payObject', payObject)
            // console.log('this.vip_fee',this.vip_fee)
            // console.log('this.bzj_fee',this.bzj_fee)
            // console.log('this.coupon.memberCardUserTicketFee',this.coupon.memberCardUserTicketFee)
            // console.log(payObject)
            // return
            this.$oucy.go('/payCenter/pay')
        },
        onSubmit: function() {
            let me = this;
            if(!this.enterpriseinfo.enterpriseSecurityDepositFee &&!this.bzj_choose &&this.vip_choose){
                this.$message('必须先交保证金才能升级为vip企业')
                return
            }
            this.queryEffectTicket()
            return
            localSet('payObject', {
                payPlatform: 'browser',
                uuid: oucy.getUUID(),
                enterpriseId: this.enterprise.id,
                earnestMoney: me.bzj_choose,
                vipMoney: me.vip_choose,
                payMode: me.payMode,
                fee: this.vip_fee + this.bzj_fee,
                type: 'enterpriseUpgrade',
            })
            oucy.replace('/payCenter/pay?type=enterpriseUpgrade')
            return

            if (1) {
                // if (this.payMode !== null && (this.bzj_choose || this.vip_choose)) {

                // this.qrCode = "12324234";
                //企业编码从哪里获取，应该缓存了吧？张工从这里开始进行支付业务逻辑即可
                oucy.postRequest('/client/financial/enterpriseupgrade/enterpriseUpgrade', {
                    payPlatform: 'browser',
                    uuid: oucy.getUUID(),
                    enterpriseId: this.enterprise.id,
                    earnestMoney: me.bzj_choose,
                    vipMoney: me.vip_choose,
                    payMode: me.payMode
                }).then(res => {
                    localSet('payObject', {
                        payPlatform: 'browser',
                        uuid: oucy.getUUID(),
                        enterpriseId: this.enterprise.id,
                        earnestMoney: me.bzj_choose,
                        vipMoney: me.vip_choose,
                        payMode: me.payMode,
                        fee: this.vip_fee + this.bzj_fee
                    })
                    oucy.replace('/payCenter/pay?type=enterpriseUpgrade')
                    return
                    //直接解析二维码
                    this.qrCode && this.qrCode.clear();
                    this.qrCode.makeCode(res.qrCode)
                    this.payExpSeconds = res.payExpSeconds
                    this.hasQrcodeShow = true
                    setTimeout(() => {
                        this.$refs.countDown.myinit()
                    })
                    console.log(res)
                })
            } else {
                console.log(666)
                // this.qrCode = null;
            }
        },
        // 
        // 根据企业编码查询企业信息
        findEnterpriseByLtUserAuthId() {
            enterpriseinfo.findEnterpriseByLtUserAuthId({ enterpriseId: this.enterprise.id }).then(res => {
                this.enterpriseinfo = res
            })
        },
        queryEffectTicket() {
            financial.queryEffectTicket({ vipMoney: this.vip_choose, earnestMoney: this.bzj_choose }).then(res => {
                if (res) {
                    if (this.vip_choose && this.bzj_choose) {
                        this.title = '保证金 + VIP 年服务费'
                    } else
                    if (this.vip_choose) {
                        this.title = '保证金'
                    } else
                    if (this.bzj_choose) {
                        this.title = 'VIP 年服务费'
                    }
                    this.couponList = res
                    this.dialogVisible = true
                } else {
                    this.gotoPay()
                }
            })
        },
        clickCoupon(v) {
            console.log(v)
            this.coupon = v
        },
    }
}
</script>
<style scoped>
.pay_bag {
    width: 100%;
    height: 800px;
    background-image: url('../../../../assets/pay_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pay_title {
    color: #fff;
    font-size: 25px;
    line-height: 50px;
}

.button_container {
    background-color: #FFFFFF;
    padding: 10px 25px;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 1200px;
    display: flex;
}

.button_container_item {
    margin-right: 25px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 5px;
    text-align: center;
    min-width: 120px;
    cursor: pointer;
    border: 1px #e3e2e2 solid;
    position: relative;
    margin: 10px;
}

.button_container_item_hover {
    border: 1px #d9efff solid;
}

.button_container_item:hover {
    border: 1px #d9efff solid;
}

.button_container_item_choose {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
}

.button_container_item_desc {
    font-size: 16px;
    color: #ff6200;
    margin-bottom: 3px;
}

.el-button--primary {
    color: #66b1ff;
    background-color: #ffffff;
}

.btnImg {
    vertical-align: inherit;
    position: relative;
    cursor: pointer;
    vertical-align: middle;
}

.coupons {
    max-height: 300px;
    overflow: auto;
    margin: 10px 0;
}

.coupon+.coupon {
    margin-top: 20px;
}
</style>